import { useState } from 'react';
import { useParams } from 'react-router';
import { getColumns } from './columns';
import { UserType } from 'shared/types/UserType';
import UsersService from 'shared/services/users.service';
import { RedesignedTable } from '../RedesignedTable';

export default function CustomersTable() {
  const [users, setUsers] = useState<UserType[] | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const { affiliateId } = useParams();

  const fetchUsers = async (query?: string) => {
    setUsers(null);
    try {
      const response = await UsersService.getAffiliateCustomers({
        pageNumber: currentPage,
        limit: rowsPerPage,
        customerId: affiliateId,
        q: query,
      });
      const { users, totalPages } = response;
      setTotalPages(totalPages);
      setUsers(users);
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  const columns = getColumns(!!affiliateId);

  return (
    <>
      <RedesignedTable
        items={users}
        columns={columns}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        fetchData={fetchUsers}
      />
    </>
  );
}
